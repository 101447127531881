// date
import {parseISO, startOfDay, subDays} from 'date-fns';

// lib
import utc from '../../libs/utc';

const getStartOfTheDayInUtc = (date) =>
  !!date ? utc(startOfDay(subDays(parseISO(date), 0))) : null;

export default getStartOfTheDayInUtc;
