// Constants
import {SET} from './constants';

// Database
import driversModel from '../../driver/database/model.database.driver';
import vehiclesModel from '../../vehicle/database/model.database.vehicle';

// Sync
import syncObjectByModificationDate from '@matthahn/sally-fw/lib/sync/lib/syncObjectByModificationDate';

export const set = (data = {}) => ({
  type: SET,
  data,
});

// Drivers
const syncDriverStorage = (drivers) => {
  driversModel().bulkPut(drivers);
};

export const syncDrivers = (newData, {storeToDb = true} = {}) => (
  dispatch,
  getState
) => {
  const oldData = getState().spotlight.drivers;
  const list = syncObjectByModificationDate(oldData, newData);
  if (storeToDb) syncDriverStorage(list);
  dispatch({
    type: SET,
    data: {drivers: list, driversLoaded: true},
  });
};

export const removeDrivers = (ids = []) => (dispatch, getState) => {
  const oldList = getState().spotlight.drivers;
  const list = [...oldList].filter((obj) => !ids.includes(obj.id));
  syncDriverStorage(list);
  dispatch({type: SET, data: {drivers: list}});
};

export const removeDriver = (id) => (dispatch, getState) => {
  const oldList = getState().spotlight.drivers;
  const list = [...oldList].filter((obj) => obj.id !== id);
  syncDriverStorage(list);
  dispatch({type: SET, data: {drivers: list}});
};

export const removeUnusedDrivers = (newList) => (dispatch, getState) => {
  const combinedList = getState().spotlight.drivers;
  const list = [...combinedList].filter(
    (obj) => !!newList.find(({id}) => obj.id === id)
  );
  syncDriverStorage(list);
  dispatch({type: SET, data: {drivers: list}});
};

// Vehicles
const syncVehicleStorage = (vehicles) => {
  vehiclesModel().bulkPut(vehicles);
};

export const syncVehicles = (newData, {storeToDb = true} = {}) => (
  dispatch,
  getState
) => {
  const oldData = getState().spotlight.vehicles;
  const list = syncObjectByModificationDate(oldData, newData);
  if (storeToDb) syncVehicleStorage(list);
  dispatch({
    type: SET,
    data: {vehicles: list, vehiclesLoaded: true},
  });
};

export const removeVehicles = (ids = []) => (dispatch, getState) => {
  const oldList = getState().spotlight.vehicles;
  const list = [...oldList].filter((obj) => !ids.includes(obj.id));
  syncVehicleStorage(list);
  dispatch({type: SET, data: {vehicles: list}});
};

export const removeVehicle = (id) => (dispatch, getState) => {
  const oldList = getState().spotlight.vehicles;
  const list = [...oldList].filter((obj) => obj.id !== id);
  syncVehicleStorage(list);
  dispatch({type: SET, data: {vehicles: list}});
};

export const removeUnusedVehicles = (newList) => (dispatch, getState) => {
  const combinedList = getState().spotlight.vehicles;
  const list = [...combinedList].filter(
    (obj) => !!newList.find(({id}) => obj.id === id)
  );
  syncVehicleStorage(list);
  dispatch({type: SET, data: {vehicles: list}});
};
