// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// driver components
import SelectDriver from '../../components/SelectDriver/SelectDriver';

// driver events
import driverSelectedEvent from '../../events/selected.event.driver';
import showSelectDriverEvent from '../../events/showSelectDriver.event.driver';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// redux
import {connect} from 'react-redux';

class SelectDriverContainer extends Component {
  static propTypes = {
    drivers: PropTypes.array,
    subscribe: PropTypes.func,
  };

  state = {
    visible: false,
    search: '',
  };

  componentDidMount() {
    this.props.subscribe(showSelectDriverEvent.subscribe(this.show));
  }

  show = () => {
    this.setState({visible: true, search: ''});
  };

  hide = () => {
    this.setState({visible: false});
  };

  onSearch = (search) => this.setState({search});

  onDriver = (driver) => () => this.selectDriver(driver);

  selectDriver = (driver) => {
    if (!driver) return alert.warning('Select a driver');
    driverSelectedEvent.publish(driver);
    this.hide();
  };

  drivers = () => {
    const {driver, drivers} = this.props;
    const {search} = this.state;
    const formattedSearch = `${search}`.toLocaleLowerCase();
    const filteredDrivers = !formattedSearch.trim().length
      ? drivers
      : [...drivers].filter((driverToFilter) =>
          `${driverToFilter.first_name} ${
            driverToFilter.last_name
          } ${driverToFilter.fhv_license_number ||
            ''} ${driverToFilter.phone_number || ''}`
            .toLowerCase()
            .includes(formattedSearch)
        );
    const driversWithoutSelectedDriver = !!driver
      ? [...filteredDrivers].filter(
          (driverToFilter) => driverToFilter.id !== driver.id
        )
      : filteredDrivers;
    const driverWithSelectedDriver = !!driver
      ? [driver, ...driversWithoutSelectedDriver]
      : driversWithoutSelectedDriver;
    return [...driverWithSelectedDriver]
      .sort((a, b) => {
        const nameA = `${a.first_name} ${a.last_name}`;
        const nameB = `${b.first_name} ${b.last_name}`;
        if (nameA > nameB) return 1;
        if (nameA < nameB) return -1;
        return 0;
      })
      .splice(0, 10);
  };

  render() {
    const {driver} = this.props;
    const {visible, search} = this.state;
    return (
      <SelectDriver
        driver={driver}
        drivers={this.drivers()}
        onClose={this.hide}
        onDriver={this.onDriver}
        onSearch={this.onSearch}
        search={search}
        visible={visible}
      />
    );
  }
}

export default connect((state) => ({drivers: state.spotlight.drivers}))(
  subscriptionHOC(SelectDriverContainer)
);
