// spotlight constants
import SPOTLIGHT_RESET_DATE from '../constants/spotlightResetDate.constant.spotlight';

// spotlight storages
import lastSpotlightResetStorage from '../storage/lastSpotlightReset.storage.spotlight';

const shouldHardResetSpotlight = ({
  currentResetDate = SPOTLIGHT_RESET_DATE(),
  previousResetDate = lastSpotlightResetStorage.get(),
} = {}) => currentResetDate !== previousResetDate;

export default shouldHardResetSpotlight;
