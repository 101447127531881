// components
import Button from '@matthahn/sally-ui/lib/components/Button/Button';

// layout components
import AttributeInput from '../../../components/components/atoms/AttributeInput/AttributeInput';
import CompactDataRow from '../../../layout/components/CompactDataRow/CompactDataRow';
import CompactLabel from '../../../layout/components/CompactLabel/CompactLabel';
import FlowScroll from '../../../layout/components/FlowScroll/FlowScroll';
import StepFooter from '../../../layout/components/StepFooter/StepFooter';

// local constants
import booleanAttributes from '../../containers/BranchGeneralInfoStepContainer/constants/booleanAttributes.constant';
import textAttributes from '../../containers/BranchGeneralInfoStepContainer/constants/textAttributes.constant';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Fragment} from 'react';

const BranchGeneralInfoStep = ({
  canEditName,
  Container,
  editMode,
  onBack,
  onChange,
  onNext,

  requires_emissions,
  requires_fhv,
  requires_rental_insurance_id,
  requires_rideshare_inspection,
  requires_tlc,

  accidents_slack_channel,
  app_reservations_allowed,
  app_reservations_per_hour,
  city,
  email,
  garage_address1,
  garage_address2,
  garage_city,
  garage_name,
  garage_phone_number,
  garage_state,
  garage_vehicle_capacity,
  garage_zipcode,
  gas_slack_channel,
  home_station,
  markets_slack_channel,
  minimum_deposit,
  minimum_rental_rate,
  name,
  ops_slack_channel,
  overview_url,
  payments_slack_channel,
  phone_number,
  registration_pickup_window,
  state,
  street_address1,
  street_address2,
  tag,
  tax_rate,
  timezone_name,
  toll_service,
  toll_tag_picture_on_launch_required,
  weekly_safetyscore_budget,
  zipcode,
}) => (
  <Fragment>
    <Container padding="none">
      <FlowScroll>
        <Container padding="none" noBorder>
          {/* General Info */}
          <CompactLabel>General Info</CompactLabel>
          <CompactDataRow label={name.label.default} wrapContent noPadding>
            <AttributeInput
              value={name}
              onChange={onChange}
              size="small"
              disabled={!canEditName || editMode}
              flat
            />
          </CompactDataRow>
          <CompactDataRow label={tag.label.default} wrapContent noPadding>
            <AttributeInput
              value={tag}
              onChange={onChange}
              size="small"
              disabled={editMode}
              flat
            />
          </CompactDataRow>
          <CompactDataRow
            label={phone_number.label.default}
            wrapContent
            noPadding
          >
            <AttributeInput
              value={phone_number}
              onChange={onChange}
              size="small"
              flat
            />
          </CompactDataRow>
          <CompactDataRow label={email.label.default} wrapContent noPadding>
            <AttributeInput
              value={email}
              onChange={onChange}
              size="small"
              flat
            />
          </CompactDataRow>
          <CompactDataRow
            label={timezone_name.label.default}
            wrapContent
            noPadding
          >
            <AttributeInput
              value={timezone_name}
              onChange={onChange}
              size="small"
              flat
            />
          </CompactDataRow>
          <CompactDataRow
            label={home_station.label.default}
            wrapContent
            noPadding
          >
            <AttributeInput
              value={home_station}
              onChange={onChange}
              size="small"
              flat
            />
          </CompactDataRow>
          <CompactDataRow
            label={toll_service.label.default}
            wrapContent
            noPadding
          >
            <AttributeInput
              value={toll_service}
              onChange={onChange}
              size="small"
              flat
            />
          </CompactDataRow>
          <CompactDataRow
            label={overview_url.label.default}
            wrapContent
            noPadding
          >
            <AttributeInput
              value={overview_url}
              onChange={onChange}
              size="small"
              flat
            />
          </CompactDataRow>

          {/* Numbers */}
          <CompactLabel>Numbers</CompactLabel>
          <CompactDataRow label={tax_rate.label.default} wrapContent noPadding>
            <AttributeInput
              value={tax_rate}
              onChange={onChange}
              size="small"
              flat
            />
          </CompactDataRow>
          <CompactDataRow
            label={minimum_deposit.label.default}
            wrapContent
            noPadding
          >
            <AttributeInput
              value={minimum_deposit}
              onChange={onChange}
              size="small"
              flat
            />
          </CompactDataRow>
          <CompactDataRow
            label={minimum_rental_rate.label.default}
            wrapContent
            noPadding
          >
            <AttributeInput
              value={minimum_rental_rate}
              onChange={onChange}
              size="small"
              flat
            />
          </CompactDataRow>
          <CompactDataRow
            label={weekly_safetyscore_budget.label.default}
            wrapContent
            noPadding
          >
            <AttributeInput
              value={weekly_safetyscore_budget}
              onChange={onChange}
              size="small"
              flat
            />
          </CompactDataRow>

          {/* Address */}
          <CompactLabel>Address</CompactLabel>
          <CompactDataRow
            label={street_address1.label.short}
            wrapContent
            noPadding
          >
            <AttributeInput
              value={street_address1}
              onChange={onChange}
              size="small"
              flat
            />
          </CompactDataRow>
          <CompactDataRow
            label={street_address2.label.short}
            wrapContent
            noPadding
          >
            <AttributeInput
              value={street_address2}
              onChange={onChange}
              size="small"
              flat
            />
          </CompactDataRow>
          <CompactDataRow label={city.label.short} wrapContent noPadding>
            <AttributeInput
              value={city}
              onChange={onChange}
              size="small"
              flat
            />
          </CompactDataRow>
          <CompactDataRow label={zipcode.label.short} wrapContent noPadding>
            <AttributeInput
              value={zipcode}
              onChange={onChange}
              size="small"
              flat
            />
          </CompactDataRow>
          <CompactDataRow label={state.label.short} wrapContent noPadding>
            <AttributeInput
              value={state}
              onChange={onChange}
              size="small"
              flat
            />
          </CompactDataRow>

          {/* Garage */}
          <CompactLabel>Garage</CompactLabel>
          <CompactDataRow label={garage_name.label.short} wrapContent noPadding>
            <AttributeInput
              value={garage_name}
              onChange={onChange}
              size="small"
              flat
            />
          </CompactDataRow>
          <CompactDataRow
            label={garage_phone_number.label.short}
            wrapContent
            noPadding
          >
            <AttributeInput
              value={garage_phone_number}
              onChange={onChange}
              size="small"
              flat
            />
          </CompactDataRow>
          <CompactDataRow
            label={garage_vehicle_capacity.label.short}
            wrapContent
            noPadding
          >
            <AttributeInput
              value={garage_vehicle_capacity}
              onChange={onChange}
              size="small"
              flat
            />
          </CompactDataRow>
          <CompactDataRow
            label={garage_address1.label.short}
            wrapContent
            noPadding
          >
            <AttributeInput
              value={garage_address1}
              onChange={onChange}
              size="small"
              flat
            />
          </CompactDataRow>
          <CompactDataRow
            label={garage_address2.label.short}
            wrapContent
            noPadding
          >
            <AttributeInput
              value={garage_address2}
              onChange={onChange}
              size="small"
              flat
            />
          </CompactDataRow>
          <CompactDataRow label={garage_city.label.short} wrapContent noPadding>
            <AttributeInput
              value={garage_city}
              onChange={onChange}
              size="small"
              flat
            />
          </CompactDataRow>
          <CompactDataRow
            label={garage_zipcode.label.short}
            wrapContent
            noPadding
          >
            <AttributeInput
              value={garage_zipcode}
              onChange={onChange}
              size="small"
              flat
            />
          </CompactDataRow>
          <CompactDataRow
            label={garage_state.label.short}
            wrapContent
            noPadding
          >
            <AttributeInput
              value={garage_state}
              onChange={onChange}
              size="small"
              flat
            />
          </CompactDataRow>
          <CompactDataRow
            label={registration_pickup_window.label.default}
            wrapContent
            noPadding
          >
            <AttributeInput
              value={registration_pickup_window}
              onChange={onChange}
              size="small"
              flat
            />
          </CompactDataRow>

          {/* Slack Channels */}
          <CompactLabel>Slack Channels</CompactLabel>
          <CompactDataRow
            label={accidents_slack_channel.label.short}
            wrapContent
            noPadding
          >
            <AttributeInput
              value={accidents_slack_channel}
              onChange={onChange}
              size="small"
              flat
            />
          </CompactDataRow>
          <CompactDataRow
            label={gas_slack_channel.label.short}
            wrapContent
            noPadding
          >
            <AttributeInput
              value={gas_slack_channel}
              onChange={onChange}
              size="small"
              flat
            />
          </CompactDataRow>
          <CompactDataRow
            label={markets_slack_channel.label.short}
            wrapContent
            noPadding
          >
            <AttributeInput
              value={markets_slack_channel}
              onChange={onChange}
              size="small"
              flat
            />
          </CompactDataRow>
          <CompactDataRow
            label={ops_slack_channel.label.short}
            wrapContent
            noPadding
          >
            <AttributeInput
              value={ops_slack_channel}
              onChange={onChange}
              size="small"
              flat
            />
          </CompactDataRow>
          <CompactDataRow
            label={payments_slack_channel.label.short}
            wrapContent
            noPadding
          >
            <AttributeInput
              value={payments_slack_channel}
              onChange={onChange}
              size="small"
              flat
            />
          </CompactDataRow>

          {/* Requirements */}
          <CompactLabel>Requirements</CompactLabel>
          <CompactDataRow
            label={toll_tag_picture_on_launch_required.label.short}
          >
            <AttributeInput
              value={toll_tag_picture_on_launch_required}
              onChange={onChange}
              size="small"
            />
          </CompactDataRow>
          <CompactDataRow label={requires_emissions.label.short}>
            <AttributeInput
              value={requires_emissions}
              onChange={onChange}
              size="small"
            />
          </CompactDataRow>
          <CompactDataRow label={requires_emissions.label.short}>
            <AttributeInput
              value={requires_emissions}
              onChange={onChange}
              size="small"
            />
          </CompactDataRow>
          <CompactDataRow label={requires_fhv.label.short}>
            <AttributeInput
              value={requires_fhv}
              onChange={onChange}
              size="small"
            />
          </CompactDataRow>
          <CompactDataRow label={requires_rental_insurance_id.label.short}>
            <AttributeInput
              value={requires_rental_insurance_id}
              onChange={onChange}
              size="small"
            />
          </CompactDataRow>
          <CompactDataRow label={requires_rideshare_inspection.label.short}>
            <AttributeInput
              value={requires_rideshare_inspection}
              onChange={onChange}
              size="small"
            />
          </CompactDataRow>
          <CompactDataRow label={requires_tlc.label.short}>
            <AttributeInput
              value={requires_tlc}
              onChange={onChange}
              size="small"
            />
          </CompactDataRow>
          {/* Reservations */}
          <CompactLabel>Reservations</CompactLabel>
          <CompactDataRow label={app_reservations_allowed.label.short}>
            <AttributeInput
              value={app_reservations_allowed}
              onChange={onChange}
              size="small"
            />
          </CompactDataRow>
          <CompactDataRow
            label={app_reservations_per_hour.label.short}
            wrapContent
            noPadding
          >
            <AttributeInput
              value={app_reservations_per_hour}
              onChange={onChange}
              size="small"
              flat
            />
          </CompactDataRow>
        </Container>
      </FlowScroll>
    </Container>
    <Container>
      <StepFooter
        left={
          !!onBack ? (
            <Button size="small" theme="grey" onClick={onBack}>
              Back
            </Button>
          ) : null
        }
        right={
          <Button size="small" theme="grey" onClick={onNext}>
            Next
          </Button>
        }
      />
    </Container>
  </Fragment>
);

BranchGeneralInfoStep.propTypes = {
  Container: PropTypes.any,
  onBack: PropTypes.func,
  onChange: PropTypes.func,
  onNext: PropTypes.func,

  ...[...booleanAttributes, ...textAttributes].reduce(
    (combined, attribute) => ({...combined, [attribute]: PropTypes.object}),
    {}
  ),
};

export default BranchGeneralInfoStep;
