import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// api lib
import {greaterThanOrEqualTo} from '@matthahn/sally-fn/lib/libs/apiQueryFilterExpressions';

// driver api
import listDriversApi from '@matthahn/sally-fn/lib/driver/api/list.api.driver';
import listRecentlyDeletedDriversApi from '@matthahn/sally-fn/lib/driver/api/listRecentlyDeleted.api.driver';

// driver database
import DB_FIELDS from '../../database/fields.database.driver';
import driversModel from '../../database/model.database.driver';

// driver storage
import lastSpotlightResetStorage from '../../storage/lastSpotlightReset.storage.driver';
import lastSpotlightSyncStorage from '../../storage/lastSpotlightSync.storage.driver';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// lib
import utc from '../../../libs/utc';

// spotlight actions
import {
  set as setAct,
  syncDrivers as syncDriversAct,
  removeUnusedDrivers as removeUnusedDriversAct,
  removeDrivers as removeDriversAct,
} from '../../../spotlight/redux/actions';

// spotlight events
import onSpotlightSearchInputEvent from '../../../spotlight/events/onSearchInput.event.spotlight';

// spotlight lib
import getStartOfTheDayInUtc from '../../../spotlight/lib/getStartOfTheDayInUtc.lib.spotlight';
import shouldResetSpotlight from '../../../spotlight/lib/shouldReset.lib.spotlight';
import shouldHardResetSpotlight from '../../../spotlight/lib/shouldHardReset.lib.spotlight';

class DriversSpotlightSync extends Component {
  static propTypes = {
    drivers: PropTypes.array,
    dispatch: PropTypes.func,
    subscribe: PropTypes.func,
  };

  componentDidMount() {
    this.init();
    this.props.subscribe(onSpotlightSearchInputEvent.subscribe(this.search));
  }

  init = async () => {
    const {dispatch} = this.props;
    const drivers = await this.getStoredDrivers();
    const shouldSpotlightBeHardReset = shouldHardResetSpotlight();
    dispatch(syncDriversAct(drivers, {storeToDb: false}));
    dispatch(setAct({driversLoading: true}));
    const lastSyncDate = lastSpotlightSyncStorage.get();
    const lastResetDate = lastSpotlightResetStorage.get();
    const shouldSpotlightBeReset =
      shouldSpotlightBeHardReset ||
      shouldResetSpotlight({
        lastReset: lastResetDate,
      });
    const freshDrivers = await this.fetch({
      lastSyncDate: shouldSpotlightBeReset ? null : lastSyncDate,
    });
    lastSpotlightSyncStorage.add(utc(new Date()));
    dispatch(syncDriversAct(freshDrivers, {storeToDb: true}));
    if (shouldSpotlightBeReset) {
      dispatch(removeUnusedDriversAct(freshDrivers));
      lastSpotlightResetStorage.add(utc(new Date()));
    } else {
      const driverIdsToRemove = await this.getDeletedDriverIds();
      dispatch(removeDriversAct(driverIdsToRemove));
    }
    dispatch(setAct({driversLoading: false, driversLoaded: true}));
  };

  getStoredDrivers = async () => {
    try {
      const drivers = await driversModel().toArray();
      return drivers;
    } catch (error) {
      return [];
    }
  };

  fetch = async ({
    drivers = [],
    limit = 400,
    offset = 0,
    lastSyncDate = null,
  } = {}) => {
    try {
      const query = {
        limit,
        offset,
        fields: DB_FIELDS.join(','),
        ordering: '-created_at',
      };
      if (!!lastSyncDate)
        query[greaterThanOrEqualTo('modified_at')] = getStartOfTheDayInUtc(
          lastSyncDate
        );
      const {results, next} = await listDriversApi(query);
      const newList = [...drivers, ...results];
      this.props.dispatch(syncDriversAct(results, {storeToDb: false}));
      if (!!next)
        return this.fetch({
          drivers: newList,
          limit,
          offset: offset + limit,
          lastSyncDate,
        });
      return newList;
    } catch (error) {
      return drivers;
    }
  };

  search = async (search) => {
    const {dispatch} = this.props;
    try {
      const {results: drivers} = await listDriversApi({
        limit: 10,
        offset: 0,
        fields: DB_FIELDS.join(','),
        ordering: '-created_at',
        search,
      });
      dispatch(syncDriversAct(drivers, {storeToDb: true}));
    } catch (error) {
      // Do nothing
    }
  };

  getDeletedDriverIds = async () => {
    try {
      const driverIds = await listRecentlyDeletedDriversApi();
      return driverIds;
    } catch (error) {
      return [];
    }
  };

  render() {
    return null;
  }
}

export default connect((state) => ({drivers: state.spotlight.drivers}))(
  subscriptionHOC(DriversSpotlightSync)
);
