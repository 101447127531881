import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

// Components
import SpotlightWrapper from '../../components/SpotlightWrapper/SpotlightWrapper';
import Spotlight from '../../components/Spotlight/Spotlight';

// Containers
import DriversSpotlightSync from '../../../driver/containers/DriversSpotlightSync/DriversSpotlightSync';
import VehiclesSpotlightSync from '../../../vehicle/containers/VehiclesSpotlightSync/VehiclesSpotlightSync';

// Constants
import DISPLAY_THRESHOLD from '../../constants/displayTreshold.constant.spotlight';

// Events
import focusOnSearchEvt from '../../events/focusOnSearch.event.spotlight';

// Routes
import driverRoute from '../../../components/pages/CustomerEditPage/route';
import vehicleRoute from '../../../components/pages/FleetEditPage/route';

// Lib
import windowScrollBlocker from '@matthahn/sally-ui/lib/libs/windowScrollBlocker';
import wait from '@matthahn/sally-fw/lib/lib/wait';
import filterUntil from '../../../libs/filterUntil';

// Types
import {phoneNumber} from '@matthahn/sally-fw/lib/type';

// spotlight constants
import SPOTLIGHT_RESET_DATE from '../../constants/spotlightResetDate.constant.spotlight';

// spotlight events
import onSearchInputEvent from '../../events/onSearchInput.event.spotlight';

// spotlight storages
import lastSpotlightResetStorage from '../../storage/lastSpotlightReset.storage.spotlight';

class SpotlightContainer extends Component {
  static propTypes = {
    drivers: PropTypes.array,
    vehicles: PropTypes.array,
    turnovers: PropTypes.array,
    dispatch: PropTypes.func,
    history: PropTypes.object,
  };

  state = {
    visible: false,
    search: '',
    showResults: false,
    lastSearch: null,
  };

  drivers = [];
  vehicles = [];

  freeScroll = () => {};

  componentDidMount() {
    this.subscribers = [focusOnSearchEvt.subscribe(this.setVisible)];
    if (this.props.driversLoaded && this.props.vehiclesLoaded)
      this.saveHardResetDate();
  }

  componentDidUpdate(prevProps, prevState) {
    const previousDisplay = this.shouldDisplay(prevState.search);
    const currentDisplay = this.shouldDisplay(this.state.search);
    this.handleWindow(previousDisplay, currentDisplay);
    const entitiesLoaded = ['driversLoaded', 'vehiclesLoaded'].every(
      (prop) => this.props[prop]
    );
    if (entitiesLoaded) this.saveHardResetDate();
  }

  componentWillUnmount() {
    this.subscribers.forEach((unsubscribe) => unsubscribe());
  }

  subscribers = [];

  hardResetDateStored = false;

  saveHardResetDate = () => {
    if (this.hardResetDateStored) return;
    this.hardResetDateStored = true;
    lastSpotlightResetStorage.add(SPOTLIGHT_RESET_DATE());
  };

  setVisible = () => {
    this.setState({visible: true});
  };

  hide = () => {
    this.setState({visible: false, search: '', showResults: false});
  };

  handleWindow(previousShow, currentShow) {
    if (!previousShow && currentShow) {
      this.freeScroll = windowScrollBlocker.add();
    } else if (previousShow && !currentShow && this.freeScroll) {
      this.freeScroll();
    }
  }

  shouldDisplay = (search = this.state.search) =>
    search.trim().length >= DISPLAY_THRESHOLD;

  onEscape = () => {
    if (!!this.state.search)
      return this.setState({search: '', showResults: false});
    this.clearAndClose();
  };

  onSearch = (search) => {
    this.setState({search}, () => this.searchForData(search));
  };

  searchForData = async (search) => {
    await wait(500);
    if (this.state.search !== search) return;
    if (search?.length > 2) onSearchInputEvent.publish(search);
    if (!this.shouldDisplay()) return this.setState({showResults: false});
    const [drivers, vehicles] = await Promise.all([
      this.loadDrivers({search}),
      this.loadVehicles({search}),
    ]);
    this.drivers = drivers;
    this.vehicles = vehicles;
    this.setState({showResults: true, lastSearch: new Date()});
  };

  loadDrivers = ({drivers = this.props.drivers, search: rawSearch} = {}) =>
    new Promise((resolve) => {
      const search = rawSearch.toLowerCase();
      const results = !this.shouldDisplay()
        ? []
        : filterUntil({
            list: drivers,
            match: (driver) => {
              const {
                id,
                first_name,
                last_name,
                phone_number,
                // email,
                fhv_license_number,
                ssn,
              } = driver;
              return [
                `${last_name}_${first_name}_${id}`,
                `${first_name} ${last_name}`,
                phone_number,
                `+1${phone_number}`,
                `${phoneNumber(phone_number).format()}`,
                // email,
                fhv_license_number,
                ssn,
              ].some(
                (attr) => !!attr && `${attr}`.toLowerCase().includes(search)
              );
            },
            stopWhen: ({list}) => list.length === 8,
          })
            .sort((a, b) => {
              const nameA = `${a.first_name} ${a.last_name}`;
              const nameB = `${b.first_name} ${b.last_name}`;
              if (nameA > nameB) return 1;
              if (nameA < nameB) return -1;
              return 0;
            })
            .slice(0, 10);
      resolve(results);
    });

  loadVehicles = ({vehicles = this.props.vehicles, search: rawSearch} = {}) =>
    new Promise((resolve) => {
      const search = rawSearch.toLowerCase();
      const results = !this.shouldDisplay()
        ? []
        : filterUntil({
            list: vehicles,
            match: (vehicle) => {
              const {svid, vin, plate, fhv_license_number} = vehicle;
              return [svid, vin, plate, fhv_license_number].some(
                (attr) => !!attr && `${attr}`.toLowerCase().includes(search)
              );
            },
            stopWhen: ({list}) => list.length === 8,
          })
            .sort((a, b) => {
              if (a.plate > b.plate) return 1;
              if (a.plate < b.plate) return -1;
              return 0;
            })
            .slice(0, 10);
      resolve(results);
    });

  filterVehicles = ({
    vehicles = [],
    foundResults = [],
    numberOfResults = 8,
    search = '',
  } = {}) => {
    const [vehicle, ...remainingVehicles] = [...vehicles];
    if (!vehicle || foundResults.length === numberOfResults)
      return foundResults;
    const {svid, vin, plate, fhv_license_number} = vehicle;
    const match = [svid, vin, plate, fhv_license_number].some(
      (attr) => !!attr && `${attr}`.toLowerCase().includes(search)
    );
    const updatedFoundResults = match
      ? [...foundResults, vehicle]
      : foundResults;
    return this.filterVehicles({
      vehicles: remainingVehicles,
      foundResults: updatedFoundResults,
      numberOfResults,
      search,
    });
  };

  clearAndClose = () => {
    this.hide();
  };

  driverClick = (driver) => () => {
    this.clearAndClose();
    this.props.history.push(driverRoute(driver.id));
  };

  vehicleClick = (vehicle) => () => {
    this.clearAndClose();
    this.props.history.push(vehicleRoute(vehicle.id));
  };

  render() {
    const {visible, search, showResults} = this.state;
    return (
      <Fragment>
        {visible && (
          <SpotlightWrapper visible={visible}>
            <Spotlight
              showResults={showResults}
              visible={visible}
              search={search}
              drivers={this.drivers}
              vehicles={this.vehicles}
              onSearch={this.onSearch}
              onClose={this.hide}
              onEscape={this.onEscape}
              onDriver={this.driverClick}
              onVehicle={this.vehicleClick}
            />
          </SpotlightWrapper>
        )}
        <DriversSpotlightSync />
        <VehiclesSpotlightSync />
      </Fragment>
    );
  }
}

export default withRouter(
  connect((state) => ({...state.spotlight}))(SpotlightContainer)
);
