const BOOLEAN_VALUES = [
  'app_reservations_allowed',
  'requires_emissions',
  'requires_fhv',
  'requires_rental_insurance_id',
  'requires_rideshare_inspection',
  'requires_tlc',
  'toll_tag_picture_on_launch_required',
];

export default BOOLEAN_VALUES;
